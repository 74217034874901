<template>
  <v-alert
    v-if="isNeedSign && pointData.canEdit"
    class="mb-2 mb-md-6 mt-4 mt-md-0"
    text="Для завершения необходимо подписать отправленные данные, а так же все файлы, требующие подписания."
    type="info"
    variant="tonal"
  />
  <div :key="getGroupValues(null, 0)?.length">
    <div v-for="(field, index) in getGroupValues(null, 0)" :key="index">
      <v-row class="border-bottom-line align-center ma-0 py-2 flex-column flex-md-row">
        <v-col class="pa-0 ma-0 flex-grow-1 font-weight-bold pr-md-4 pr-0 mb-2 mb-md-0">
          <template v-if="getIsShowNameField(field, index, getGroupValues(null, 0))">{{
            getFieldName(field.fieldCode)
          }}</template>
        </v-col>
        <v-col class="pa-0 ma-0 flex-grow-1 d-flex align-center justify-start">
          <v-icon v-if="field.isAgree">mdi-check-bold</v-icon>
          <template v-else>{{ getFieldValue(field) || '-' }}</template>
        </v-col>
      </v-row>
      <div v-if="getDescription(field.fieldCode)" class="text-caption text-grey-darken-1">
        <div class="mt-2 pa-4" v-html="getDescription(field.fieldCode)"></div>
      </div>
    </div>
    <div v-for="group in groupsFields" :key="group.id" class="pt-2">
      <div class="mb-4 text-body-1 ma-0 mt-4 text-subtitle-1 font-weight-bold text-grey">
        {{ group.name }}
      </div>
      <div class="border mb-2 pa-3 pa-md-5">
        <template v-if="getGroupValues(group.code1C, group.index)?.length">
          <div v-for="(field, index) in getGroupValues(group.code1C, group.index)" :key="field.id">
            <v-row
              :class="[
                'align-center ma-0 py-2 flex-column flex-md-row',
                index !== getGroupValues(group.code1C, group.index).length - 1 &&
                  'border-bottom-line',
              ]"
            >
              <v-col class="pa-0 ma-0 flex-grow-1 font-weight-bold pr-4 mb-2 mb-md-0">
                <template
                  v-if="getIsShowNameField(field, index, getGroupValues(group.code1C, group.index))"
                >
                  {{ getFieldName(field.fieldCode) }}</template
                >
              </v-col>
              <v-col class="pa-0 ma-0 flex-grow-1 d-flex align-center justify-start">
                <v-icon v-if="field.isAgree" color="green" size="xs">mdi-check-bold</v-icon>
                <template v-else>{{ getFieldValue(field) || '-' }}</template>
              </v-col>
            </v-row>
            <div v-if="getDescription(field.fieldCode)" class="text-caption text-grey-darken-1">
              <div class="mt-2 pa-4" v-html="getDescription(field.fieldCode)"></div>
            </div>
          </div>
        </template>
        <div v-else class="text-grey text-caption text-center">Нет данных</div>
      </div>
    </div>
  </div>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else-if="Object.keys(groupNames)?.length" :class="[point.signable ? 'mb-6' : 'mb-12']">
    <div v-if="files?.length" class="font-weight-bold my-6">Скачать пакет документов:</div>
    <div v-for="(group, groupIndex) in Object.keys(groupNames)" :key="group">
      <div class="font-weight-bold w-100 my-4">
        {{ Object.values(groupNames)[groupIndex] }}
      </div>
      <div
        v-for="(fields, fieldsIndex) in Object.values(
          getFieldsListByField(getFieldsByGroupArr(group), 'attachCode'),
        )"
        :key="
          Object.keys(getFieldsListByField(getFieldsByGroupArr(group), 'attachCode'))[fieldsIndex]
        "
        class="ml-0 mx-md-3"
      >
        <div class="my-4 font-weight-bold text-subtitle-2 text-grey">
          {{
            getAttachFieldName(
              Object.keys(getFieldsListByField(getFieldsByGroupArr(group), 'attachCode'))[
                fieldsIndex
              ],
            )
          }}
        </div>
        <div
          v-for="file in fields"
          :key="`file-${file.id}`"
          class="pl-2 pl-md-2 w-100 pa-md-0 pa-2 ma-0 align-md-center flex-nowrap flex-column flex-md-row my-2 mx-0 file-wrapper"
        >
          <v-row
            class="pa-0 ma-0 justify-space-between align-center"
            @click="() => downloadFile(file.fileId, file?.originalFileName)"
          >
            <v-col class="pa-md-0 pa-0 ma-0" cols="12" md="8" lg="8">
              <v-icon color="grey" class="d-inline">{{ getFileIcon(file?.fileName) }}</v-icon>
              <span class="ml-2 font-weight-medium text-caption">{{ file?.fileName }}</span>
            </v-col>
            <v-col cols="12" md="4" lg="4" class="pa-0 ma-0">
              <v-row class="ma-0 pa-0 align-center justify-end w-100">
                <div class="text-caption">
                  <div v-if="file.signValid" class="text-green">
                    <v-icon size="xs" color="green" class="mr-2 d-inline">mdi-check</v-icon>
                    Документ подписан
                  </div>
                  <div v-else-if="file.signable" class="text-orange">
                    <v-icon size="xs" color="orange" class="mr-2 d-inline">mdi-pen</v-icon>
                    Требуется подпись
                  </div>
                </div>
                <div>
                  <v-btn class="text-caption text-blue-darken-4 ml-2" variant="text">
                    <v-icon color="blue" size="xs"> mdi-download </v-icon>
                    <span class="ml-2 text-body-2 title">Скачать</span>
                  </v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>
          <template v-if="getFileDescription(file.attachCode)">
            <div
              class="text-caption text-grey-darken-1 mt-2 py-2"
              v-html="getFileDescription(file.attachCode)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
  <v-row v-if="point.signValid" :key="point.signValid" class="ma-0 text-grey mb-4">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
        stroke="#3F9946"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 4L12 14.01L9 11.01"
        stroke="#3F9946"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="ml-2 text-green font-weight-bold">Подписан</span>
  </v-row>
  <div class="pt-0 pt-md-12">
    <v-row class="justify-space-between align-center pa-0 ma-0 mt-6 mt-md-12 mb-12 pb-12">
      <v-row class="px-0 align-center mx-0">
        <v-btn :loading="isLoadPdf" class="mr-2" color="primary" @click="downloadPdf"
          >Скачать</v-btn
        >
        <v-btn
          :disabled="isGetPdfLoading"
          variant="outlined"
          color="primary"
          :loading="isGetPdfLoading"
          class="mx-2"
          @click="getFileForSign(true)"
        >
          <v-row class="pa-md-0 pa-0 ma-0 align-center" cols="12" md="8" lg="8">
            <v-icon color="grey" class="d-inline">mdi-file-pdf-box</v-icon>
            <span class="ml-2 font-weight-medium text-caption font-weight-bold"
              >Выгрузка в PDF</span
            >
          </v-row>
        </v-btn>
        <v-btn class="text-decoration-underline" color="primary" variant="text" @click="goBack">
          Закрыть
        </v-btn>
      </v-row>
      <v-btn
        v-if="pointData.canHold"
        color="red"
        @click="isShowWithdrawAppDialog = !isShowWithdrawAppDialog"
        >Отозвать</v-btn
      >
    </v-row>
  </div>
  <v-dialog v-model="isShowWithdrawAppDialog" max-width="400">
    <v-card class="w-100">
      <v-card-text>
        Данные, поданные в рамках "<span v-html="pointData.name" />" будут отозваны. Вы согласны?
        После этого вы сможете отредактировать "<span v-html="pointData.name" />" и сформировать
        <template v-if="pointData.closedate"
          >повторно, до {{ getFormatDate(pointData.closedate) }}.</template
        >
        <template v-else>повторно.</template>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center">
        <v-btn color="red" @click="onWithdrawApp">Отозвать</v-btn>
        <v-btn color="primary" variant="text" @click="isShowWithdrawAppDialog = false">
          Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-row
    v-if="isNeedSign && pointData.canSign"
    style="z-index: 999999"
    class="ma-0 w-100 position-fixed bg-white pa-3 pa-md-6 form-footer"
  >
    <div class="w-100">
      <v-row
        class="ma-0 pa-0 flex-grow-1 align-md-center w-100 d-flex mt-4 flex-column flex-md-row"
      >
        <v-select
          v-model="currentCertificate"
          :items="certificates"
          label="Сертификат"
          item-title="text"
          class="mb-4 mb-md-0"
          item-value="value"
          variant="underlined"
          hide-details="auto"
          return-object
        />
        <v-btn
          v-if="isNeedSign"
          :disabled="!currentCertificate || isLoadingSignFile"
          color="primary"
          :loading="isLoadingSignFile"
          class="ml-md-2 mb-0 btn-submit"
          @click="handleToggleSignAllFilesDialog"
        >
          Подписать и отправить
        </v-btn>
      </v-row>
      <pre v-if="errorMsg" class="mt-2 text-red">{{ errorMsg }}</pre>
    </div>
  </v-row>
  <v-dialog v-model="isShowDialogSignFiles">
    <v-card max-width="800" class="w-100">
      <v-card-title class="font-weight-bold">
        <v-row class="pa-0 ma-0 justify-space-between">
          <span>Подписание {{ pointData?.name ? `«${pointData?.name}»` : '' }}</span>
          <v-btn
            prepend-icon="mdi-close"
            variant="flat"
            class="close-button"
            @click="handleCloseModal"
          />
        </v-row>
      </v-card-title>
      <v-card-text :key="signHistory" class="mb-4">
        <v-row
          v-for="(item, index) in signHistory"
          :key="`history-${item.sign}-${index}`"
          class="pa-0 ma-0 align-center"
        >
          <span :class="[item.errorText && 'text-red']">{{ item.text }}</span>
          <div class="mx-2 d-flex align-center justify-center">
            <v-icon v-if="item.sign" color="green">mdi-check</v-icon>
            <v-icon v-else-if="item.errorText" size="xs" color="red"
              >mdi-alert-circle-outline</v-icon
            >
            <div v-else-if="item.isError"></div>
            <v-icon v-else>
              <v-progress-circular indeterminate color="primary" :size="15" :width="2" />
            </v-icon>
          </div>
          <div v-if="item.errorText" class="text-red">({{ item.errorText }})</div>
        </v-row>
      </v-card-text>
      <v-card-text v-if="signError" class="mt-0 pt-0 text-red pb-6">
        <v-alert :text="signError" type="error" color="red" variant="tonal" />
      </v-card-text>
      <v-card-actions v-if="!isLoadSign" class="justify-end">
        <v-btn color="primary" variant="flat" @click="handleToggleSignAllFilesDialog">Готово</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { useRouter } from 'vue-router/dist/vue-router'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import moment from 'moment'
import { api } from '@/api/Api'
import { downloadFileWithLinkData, getFileIcon, getFormatDate } from '@/helpers'
import { useRoute } from 'vue-router'
import { getUserCertificates, createHash, createDetachedSignature } from 'crypto-pro'
import { useToast } from '@/composables/useToast'
import { emit as baseEmit } from '@/composables/form/usePropsForm'

const router = useRouter()
const route = useRoute()
const { toast } = useToast()

const SIGN_CODE = '16534e9c-2dea-41a4-9df0-26ebe750f6ae'

const emit = defineEmits(baseEmit)

const props = defineProps({
  pointData: {
    type: Object,
    default: null,
  },
})

const groups = computed(() => props.pointData?.templateGroups)
const point = computed(() => props.pointData)
const isNeedSign = computed(
  () =>
    props.pointData?.status?.code1C === SIGN_CODE && point.value.signable && isShowSignForm.value,
)

const isLoadSign = computed(() => !!signHistory.value?.find((el) => !el.errorText && !el.sign))
const isGetPdfLoading = ref(false)
const currentCertificate = ref(null)
const errorMsg = ref('')
const signFile = ref()
const isShowSignForm = ref(true)
const signFileBase64 = ref()
const isLoadingSignFile = ref(false)
const certificates = ref([])
const formatFields = ref([])
const signHistory = ref([])
const groupsFields = ref(null)
const files = ref([])
const groupsNames = ref({})
const fieldsNames = ref(null)
const attachFieldsNames = ref(null)
const isLoading = ref(false)
const isSignForm = ref(false)
const isLoadPdf = ref(false)
const isShowDialogSignFiles = ref(false)
const filesForSign = ref([])
const signError = ref('')

const groupNames = ref({})
const fieldNames = ref({})
const isShowWithdrawAppDialog = ref(false)

const getDescription = (fieldCode) => {
  const field = props.pointData?.templateFields?.find((el) => el.code1C === fieldCode)
  if (!field || !field?.printDescription) {
    return ''
  }

  if (field.printDescription) {
    return field.description
  }
}

const onWithdrawApp = async () => {
  try {
    await api
      .projects()
      .withdrawApp(point.value?.id)
      .then(() => {
        isShowWithdrawAppDialog.value = false
        location.reload()
      })
  } catch (err) {
    console.log(err)
    if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message)
    }
  }
}

const handleToggleSignAllFilesDialog = () => {
  const newValue = !isShowDialogSignFiles.value
  isShowDialogSignFiles.value = newValue

  if (!newValue) {
    signHistory.value = []
    errorMsg.value = ''
    getSignedFiles(true)
    location.reload()
    return
  }
  nextTick(() => {
    signFilesList()
  })
}

const signFilesList = async () => {
  await startSign(0)
}

const startSign = async (i) => {
  const index = i || 0
  if (!filesForSign.value?.length) {
    return createSignatureClick()
  }

  const file = filesForSign.value[index]

  signHistory.value = [
    ...signHistory.value,
    {
      text: `Подписываем ${index + 1} файл из ${filesForSign.value.length}`,
      sign: false,
      isError: false,
    },
  ]
  await signFileAction(file, index).finally(() => {
    if (index + 1 < filesForSign.value.length) {
      startSign(index + 1)
    }
  })
}

const sendSignFile = async (sign, id, index) => {
  api
    .files()
    .saveSignFile(id, sign)
    .then(() => {
      signHistory.value[index].sign = true
    })
    .catch((err) => {
      signHistory.value[index].isError = true
      signHistory.value[index].errorText = err?.response?.data?.message || err?.response?.data
    })
}

async function hashBase64Data(base64Data) {
  try {
    const binaryString = atob(base64Data)
    const dataBytes = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      dataBytes[i] = binaryString.charCodeAt(i)
    }

    const hashOptions = {
      algorithm: 'GOST R 34.11-2012',
      hashSize: 256,
    }
    const dataHash = await createHash(dataBytes, hashOptions)

    console.log('Хеш данных из Base64 (ГОСТ Р 34.11-2012): ', dataHash)
    return dataHash
  } catch (error) {
    console.error('Ошибка при хешировании данных из Base64: ', error)
    throw error
  }
}

const sendFileForFile = async (base64, file, index) => {
  const thumbprint = currentCertificate.value?.value
  try {
    // eslint-disable-next-line no-unused-vars
    let hashValue = ''
    const signOptions = {
      detached: true,
      includeWholeChain: true,
    }
    await hashBase64Data(base64).then((hash) => {
      hashValue = hash
    })
    const signature = await createDetachedSignature(thumbprint, hashValue, signOptions)
    await sendSignFile(signature, file.id, index).then(() => {
      if (filesForSign.value.length === index + 1) {
        const hasErr = !!signHistory.value?.find((el) => !!el.errorText)
        if (hasErr) {
          return
        }
        createSignatureClick()
      }
    })
    errorMsg.value = ''
  } catch (e) {
    signHistory.value[index].errorText = e.message
    errorMsg.value = `${e.message}`
  }
}

const signFileAction = async (file, index) => {
  await api
    .files()
    .getBase64File(file.id)
    .then((data) => {
      sendFileForFile(data?.content, file, index)
    })
    .catch((err) => {
      console.log(err, '====')
      signHistory.value = [
        ...signHistory.value,
        {
          text: `- Ошибка при подписании файла ${index + 1}`,
          sign: false,
          isError: true,
        },
      ]
    })
}
const getFieldsListByField = (array, key) => {
  return array.reduce((accumulator, object) => {
    ;(accumulator[object[key]] ??= []).push(object)
    return accumulator
  }, {})
}

const getFieldsByGroupArr = (groupCode) => {
  return files.value?.filter((field) => field.groupCode === groupCode)
}

const getFileDescription = (fieldCode) => {
  const field = props.pointData?.templateAttachments?.find((el) => el.code1C === fieldCode)
  if (!field || !field?.printDescription) {
    return ''
  }

  if (field.printDescription) {
    return field.description
  }
}

function getIsShowNameField(field, index, group) {
  return (
    !index ||
    !group[index - 1] ||
    getFieldName(group[index - 1].fieldCode) !== getFieldName(field.fieldCode)
  )
}

function getGroupValues(id, groupIndex) {
  if (!id) {
    return formatFields.value.filter((field) => !field.groupCode)?.sort((a, b) => a.sort - b.sort)
  } else
    return formatFields.value
      .filter((el) => el.groupCode === id && el.groupIndex === groupIndex)
      ?.sort((a, b) => (a.code1C > b.code1C ? -1 : 1))
      .sort((a, b) => a.sort - b.sort)
}

function getFieldName(id) {
  return fieldsNames.value && id && id in fieldsNames.value ? fieldsNames.value[id] : ''
}

function getAttachFieldName(id) {
  return id in attachFieldsNames.value ? attachFieldsNames.value[id] : ''
}

async function downloadZip(id, name) {
  try {
    await api
      .files()
      .downloadZipProject(id)
      .then((data) => {
        downloadBlob(data, `${name}.zip`)
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoadPdf.value = false
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .downloadFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoadPdf.value = false
    isGetPdfLoading.value = false
  }
}

function getFieldValue(field) {
  if (!field.value) {
    return '-'
  }

  switch (field?.fieldType?.dataType) {
    case 'date': {
      const isValidDate = moment(field.value, true).isValid()
      return isValidDate ? moment(field.value).format('DD.MM.YYYY') : field.value
    }
    case 'datetime-local': {
      const isValidDate = moment(field.value, true).isValid()
      return isValidDate ? moment(field.value).format('DD.MM.YYYY HH:mm:ss') : field.value
    }
    default:
      return field.value || ''
  }
}

async function getSignedFiles(isUpdate) {
  isLoading.value = true
  try {
    await api
      .files()
      .getSignedFiles({
        [`filter[pointId]`]: route.params.id,
      })
      .then((data) => {
        files.value = isUpdate ? [...data] : [...files.value, ...data]
        filesForSign.value = isUpdate
          ? data?.filter((el) => !!el.signable && !el.signValid)
          : [...filesForSign.value, ...data?.filter((el) => !!el.signable && !el.signValid)]
        files.value
          ?.sort((a, b) => a.sort - b.sort)
          ?.forEach((file) => {
            groupNames.value[file.groupCode] = ''
            fieldNames.value[file.attachCode] = ''
          })
        props.pointData?.templateGroups?.forEach((group) => {
          if (group.code1C in groupNames.value) {
            groupNames.value[group.code1C] = group.name
          }
        })
        props.pointData?.templateAttachments?.forEach((field) => {
          if (field.code1C in fieldNames.value) {
            fieldNames.value[field.code1C] = field.name
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function goBack() {
  router.push('/tasks')
}

function getForm() {
  let fieldsGroup = {}
  props.pointData?.templateFields?.forEach((field) => {
    fieldsGroup[field.code1C] = field.groupCode
    fieldsNames.value = {
      ...fieldsNames.value,
      [field.code1C]: field.name,
    }
  })
  props.pointData?.templateAttachments?.forEach((field) => {
    attachFieldsNames.value = {
      ...attachFieldsNames.value,
      [field.code1C]: field.name,
    }
  })
  const fields = props.pointData.templateFields
  formatFields.value = props.pointData.values.map((field) => {
    const fieldFind = fields?.find((el) => el.code1C == field.fieldCode)
    const sort = fieldFind?.sort || 0
    const isAgree = fieldFind?.fieldType?.id === 16 && field.value === 'Да'
    return {
      ...field,
      sort: sort,
      isAgree,
      groupCode: fieldsGroup[field.fieldCode] || null,
    }
  })
  groups.value.forEach((group) => {
    groupsFields.value = {
      ...groupsFields.value,
      [`${group.code1C}-0`]: {
        ...group,
        id: `${group.code1C}-0`,
        index: 0,
      },
    }
  })
  formatFields.value?.forEach((field) => {
    if (field.groupIndex && !(`${field.groupCode}-${field.groupIndex}` in groupsFields.value)) {
      const group = groups?.value.find((groupEl) => groupEl.code1C === field.groupCode)
      if (!group) {
        return
      }
      groupsFields.value = {
        ...groupsFields.value,
        [`${field.groupCode}-${field.groupIndex}`]: {
          ...group,
          index: field.groupIndex,
        },
      }
    }
  })
  groups.value?.forEach((group) => {
    groupsNames.value = { ...groupsNames.value, [group.code1C]: group.name }
  })
}

const createSignatureClick = async () => {
  const index = signHistory.value.length + 1
  signHistory.value = [
    ...signHistory.value,
    {
      text: `Подписываем проектную точку`,
      sign: false,
      isError: false,
    },
  ]
  isLoadingSignFile.value = true
  await getFileForSign()
    .then(() => {
      createSignatureStartProcess()
      signHistory.value[index].sign = true
      signError.value = ''
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        signError.value = err?.response?.data?.message
        toast.error(err?.response?.data?.message)
      }
      signHistory.value[signHistory.value.length - 1].isError = true
      isLoadingSignFile.value = false
    })
}

const createSignatureStartProcess = async () => {
  const thumbprint = currentCertificate.value?.value
  try {
    let hashValue = ''
    const signOptions = {
      detached: true,
      includeWholeChain: true,
    }
    await hashBase64Data(signFileBase64.value).then((hash) => {
      hashValue = hash
    })
    const signature = await createDetachedSignature(thumbprint, hashValue, signOptions)
    if (signature) {
      await signFormFile(signature)
    }
    errorMsg.value = ''
  } catch (e) {
    errorMsg.value = `${e.message}`
  } finally {
    isLoadingSignFile.value = false
  }
}

async function signFormFile(signature) {
  try {
    const params = new FormData()
    params.append('sign', signature)
    params.append('fileBase64', signFileBase64.value)
    await api
      .projects()
      .signFile(point.value.id, params)
      .then(() => {
        toast.success('Анкетные данные успешно подписаны')
        isSignForm.value = true
        isShowSignForm.value = false
        signError.value = ''
      })
  } catch (err) {
    if (err?.response?.data?.message) {
      signError.value = err?.response?.data?.message
      toast.error(err?.response?.data?.message)
    }
    console.log(err)
  }
}

async function getFileForSign(isDownload) {
  isGetPdfLoading.value = true
  try {
    if (point.value.printFileId) {
      return downloadFile(point.value.printFileId, point.value?.name)
    }
    const file = await api.projects().getFileForSign(point.value.id)
    const base64File = await api.projects().getFileForSignString(point.value.id, true)
    const blobFile = await api.projects().getFileForSign(point.value.id)
    signFile.value = file
    signFileBase64.value = base64File

    if (isDownload) {
      downloadBlob(blobFile, `${point.value.name}.pdf`)
    }
  } catch (err) {
    console.log(err)
  } finally {
    isGetPdfLoading.value = false
    isLoadPdf.value = false
  }
}

function downloadBlob(blob, name = 'file.txt') {
  if (window.navigator && window.navigator.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob)
  const data = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = data
  link.download = name
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  setTimeout(() => {
    window.URL.revokeObjectURL(data)
    link.remove()
  }, 100)
}

function downloadPdf() {
  isLoadPdf.value = true
  downloadZip(point.value?.id, point.value?.name)
}

const handleCloseModal = () => {
  isShowDialogSignFiles.value = !isShowDialogSignFiles.value
  signError.value = ''
  signHistory.value = []
}

async function waitForPlugin() {
  try {
    await cadesplugin
    console.log('Плагин успешно загружен')
  } catch (error) {
    console.error('Ошибка загрузки плагина:', error)
  }
}

const getCerts = async () => {
  //const errorMsg = document.getElementById('errorMessage')
  try {
    console.log('-----')
    await waitForPlugin()
    console.log('------')
    const certificateList = await getUserCertificates()
    certificateList.forEach(({ name, thumbprint, validTo }) => {
      certificates.value = [
        ...certificates.value,
        {
          text: `${name} (действителен до: ${new Date(validTo).toLocaleString()})`,
          value: thumbprint,
        },
      ]
    })
  } catch (e) {
    errorMsg.value = `\n${e.message}`
  }
}

const getData = (isUpdate) => {
  getSignedFiles(isUpdate)
  getForm()

  if (isNeedSign.value && point.value?.canSign) {
    getCerts()
  }
}

watch(isSignForm, () => {
  if (isSignForm.value) {
    emit('update')
    getData(true)
  }
})

onMounted(() => {
  getData()
})
</script>
<style lang="scss" scoped>
.btn-submit {
  min-height: 48px;
}

.form-footer {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  bottom: 0;
  left: 0;
}

.file-wrapper {
  transition: all 0.3s;
  border-radius: 8px;

  &:hover {
    background: #f0f0f06e;
  }
}
</style>
<style lang="scss">
.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;

  .v-btn__prepend {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 0;
  }
}
</style>
