<template>
  <template v-if="isManager || isExpert || isAdmin">
    <div class="font-weight-bold mt-6 ml-2 mb-6">Пользователи и роли</div>

    <div class="d-flex flex-row mb-2">
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">Группы</div>
        <v-select
          v-model="selectedGroup"
          :items="groupList"
          return-object
          item-title="name"
          item-value="id"
          variant="outlined"
          hide-details="auto"
          clearable
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 text-no-wrap">E-Mail</div>
        <v-text-field
          v-model="searchFieldValueMail"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">Телефон</div>
        <v-text-field
          v-model="searchFieldValuePhone"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
    </div>
    <div class="d-flex flex-row mb-12">
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">Город</div>
        <v-text-field
          v-model="searchFieldValueCity"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">Название компании</div>
        <v-text-field
          v-model="searchFieldValueCompanyName"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
      <v-sheet
        class="pa-2 flex-grow-1 centered-input"
        style="flex-basis: 0; flex-grow: 1; background: rgba(255, 255, 255, 0)"
      >
        <div class="flex-grow-0 labelTitle">Имя</div>
        <v-text-field
          v-model="searchFieldValueUserName"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details="auto"
          clearable
          class="centered-input"
        />
      </v-sheet>
    </div>
  </template>
  <v-row v-if="isLoading" class="px-0 justify-center align-center">
    <v-progress-circular color="primary" :indeterminate="true" />
  </v-row>
  <TheTable v-else :items="users" :total-count="totalCount" @update-page="updatePage">
    <Column field="id" header="ID" :sortable="true" />
    <Column field="fio" style="width: 150px" header="ФИО" :sortable="true" class="text-break" />
    <Column field="email" header="E-Mail" :sortable="true" />
    <Column field="phone" header="Телефон" :sortable="true" />
    <Column field="groups" header="Группы">
      <template #body="{ data: { groups } }">
        <span>{{ setGroups(groups) }}</span>
      </template>
    </Column>
    <Column field="addGroups">
      <template #body="{ data: { id, groups } }">
        <v-btn
          variant="text"
          color="primary"
          class="text-caption"
          @click.prevent="addGroup(id, groups)"
        >
          Назначить группу
        </v-btn>
      </template>
    </Column>
    <Column field="token" style="width: 150px">
      <template #body="{ data }">
        <div :class="['text-caption', { 'text-red': checkDteHasCome(data?.tokenExpireAt) }]">
          Токен действителен до: {{ getFormatDateTime(data?.tokenExpireAt) }}
        </div>

        <div
          class="mt-2 text-caption text-primary cursor-pointer pa-2 bg-blue-grey-lighten-5 text-center"
          @click="() => handleUserUpdateClick(data)"
        >
          <div v-if="loadingToken === data?.token" class="justify-center align-center d-flex">
            <v-progress-circular indeterminate color="primary" :size="20" :width="1" />
          </div>
          <div v-else class="text-caption">Обновить токен</div>
        </div>
      </template>
    </Column>
    <Column field="login">
      <template #body="{ data: { token, tokenExpireAt } }">
        <v-btn
          icon="mdi-login"
          elevation="0"
          variant="tonal"
          size="x-small"
          :disabled="checkDteHasCome(tokenExpireAt)"
          @click="loginWithUserToken(token, tokenExpireAt)"
        />
        <v-tooltip activator="parent" location="right">
          Войти как выбранный пользователь
        </v-tooltip>
      </template>
    </Column>
  </TheTable>
  <UsersAddGroupDialog
    ref="addGroupDialog"
    :user-id="selectedUserId"
    :user-groups="selectedGroups"
    :page="tablePage"
    @on-close="resetFormGroups"
  />
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card
      :text="`Обновление токена может вызвать разрыв сессии пользователя. Вы уверены что хотите обновить токен пользователя “${user?.fio}”`"
    >
      <template #actions>
        <v-spacer></v-spacer>

        <v-btn @click="dialog = false"> Отмена </v-btn>

        <v-btn color="primary" variant="elevated" @click="updateToken"> Подтвердить </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import TheTable from '@/components/TheTable'
import Column from 'primevue/column'
import { replaceRole } from '@/utils/common'
import UsersAddGroupDialog from '@/components/Users/UsersAddGroupDialog'
import { useStore } from 'vuex'
import { getFormatDateTime } from '../../helpers'
import moment from 'moment'
import { api } from '@/api/Api'
import { tokenService } from '@/services/token'

defineProps({
  users: {
    type: Array,
    default: () => [],
  },
  totalCount: {
    type: Number,
    default: () => 0,
  },
  uniqueUsers: {
    type: Array,
    default: () => [],
  },
  groupList: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update-page', 'update-search', 'update-user-table', 'update-list'])
const tablePage = ref(null)
const dialog = ref(false)

const updatePage = (page) => {
  emit('update-page', page)
  tablePage.value = page
}
const handleUserUpdateClick = (data) => {
  user.value = data
  dialog.value = true
}
const user = ref(null)
const loadingToken = ref(null)
const addGroupDialog = ref(null)
const selectedUserId = ref(null)
const selectedGroups = ref([])
const store = useStore()
const selectedUser = ref(null)
const selectedGroup = ref(null)

const searchFieldValueMail = ref(null)
const searchFieldValuePhone = ref(null)
const searchFieldValueCity = ref(null)
const searchFieldValueCompanyName = ref(null)
const searchFieldValueUserName = ref(null)

const isManager = computed(() => store.getters['user/isManager'])
const isExpert = computed(() => store.getters['user/isExpert'])
const isAdmin = computed(() => store.getters['user/isAdmin'])

const checkDteHasCome = (date) => {
  const currentDateTime = moment()
  return currentDateTime.isSameOrAfter(date)
}

const loginWithUserToken = (token, tokenExpireAt) => {
  if (token && !checkDteHasCome(tokenExpireAt)) {
    tokenService.saveToken(token)
    window.location.reload()
  }
}

const updateToken = async () => {
  if (!user.value) {
    return
  }
  const { renew_token, token } = user.value
  dialog.value = true
  if (loadingToken.value) {
    return
  }
  loadingToken.value = token
  try {
    await api
      .user()
      .updateToken(renew_token)
      .then((data) => {
        store.commit('user/updateUsersList', { ...data, oldToken: token })
      })
      .finally(() => {
        loadingToken.value = null
        dialog.value = false
        user.value = null
      })
  } catch (err) {
    console.log(err)
  }
}

function concatGroup(groups) {
  return groups?.map((group) => replaceRole(group.groupId)).join(', ')
}

function setGroups(groups) {
  return groups?.length === 0 ? 'нет группы' : concatGroup(groups)
}

function addGroup(userId, groups) {
  if (addGroupDialog.value) {
    selectedUserId.value = userId
    selectedGroups.value = groups
    addGroupDialog.value.open()
  }
}

function resetFormGroups() {
  selectedUserId.value = null
  selectedGroups.value = []
}

watch(selectedUser, () => {
  const userIdVal = selectedUser.value?.id
  if (userIdVal !== null && userIdVal !== undefined) {
    store.dispatch('user/filterQuestionnaireUser', userIdVal)
  }
})

watch(selectedGroup, () => {
  emit('update-search', 'group_id', selectedGroup.value?.id)
})

watch(searchFieldValueMail, (value) => {
  emit('update-search', 'email', value)
})
watch(searchFieldValuePhone, (value) => {
  emit('update-search', 'phone', value)
})
watch(searchFieldValueCity, (value) => {
  emit('update-search', 'city', value)
})
watch(searchFieldValueCompanyName, (value) => {
  emit('update-search', 'work_company', value)
})
watch(searchFieldValueUserName, (value) => {
  emit('update-search', 'name', value)
})
</script>

<script>
export default {
  name: 'UsersTable',
}
</script>
<style lang="scss">
.centered-input {
  display: grid;

  .v-input__control {
    .v-field__prepend-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
    }

    input {
      height: 56px;
    }
  }

  .v-field__clearable {
    .v-icon--clickable {
      margin-top: 8px;
    }
  }
}
</style>
