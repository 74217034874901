import { api } from '@/api/Api'
import {
  ADMIN,
  ADMIN_COMPETITION,
  EXPERT,
  MANAGER,
  roles,
  USER,
  MONITOR,
  MONITOR_CONTENT,
  WINNER,
} from '@/enums/roles'

const state = {
  userInfo: {
    id: null,
    username: null,
    fio: null,
    phone: null,
    email: null,
    firstname: null,
    middlename: null,
    lastname: null,
    avatar_path: null,
    avatar_base_url: null,
    birthday: null,
    gender: null,
    country: null,
    region: null,
    city: null,
    site: null,
    work_position: null,
    work_company: null,
    groups: [],
    created_at: null,
    logged_at: null,
  },
  users: [],
  currentPage: 1,
  pageCount: null,
  totalCount: null,
  filteredUsers: [],
  uniqueUsersList: [],
  selectedUser: null,
  selectedGroupId: null,
  email: null,
  phone: null,
  city: null,
  work_company: null,
}

const getters = {
  userInfo: (state) => state.userInfo,
  currentPage: (state) => state.currentPage,
  pageCount: (state) => state.pageCount,
  totalCount: (state) => state.totalCount,
  userName: (state) => {
    const { lastname, firstname } = state.userInfo || {}
    if (!lastname && !firstname) {
      return null
    }
    return `${lastname || ''} ${firstname || ''}`.trim() || null
  },
  currentUserId: (state) => state.userInfo?.id || null,
  currentRoles: (state) => (state.userInfo?.groups || []).map((group) => group.id),
  allUsers: (state) => state.users,
  allFilteredUsers: (state) => state.filteredUsers,
  allUniqueUsersList: (state) => state.uniqueUsersList,
  isSelectedUser: (state) => state.selectedUser,
  isWinner: (_, getters) => getters.currentRoles.includes(WINNER),
  isAdminCompetition: (_, getters) => getters.currentRoles.includes(ADMIN_COMPETITION),
  isAdmin: (_, getters) => getters.currentRoles.includes(ADMIN),
  isManager: (_, getters) => getters.currentRoles.includes(MANAGER),
  isUser: (_, getters) => getters.currentRoles.includes(USER),
  isMonitor(_, getters) {
    return getters.currentRoles.includes(MONITOR)
  },
  isMonitorContent(_, getters) {
    return getters.currentRoles.includes(MONITOR_CONTENT)
  },
  isExpert: (_, getters) => getters.currentRoles.includes(EXPERT),
  userRoleInString: (_, getters) =>
    getters.currentRoles
      .map((role) => roles[role])
      .reverse()
      .join(', '),
  selectedGroupId: (state) => state.selectedGroupId,
}

const actions = {
  async getUserInfo({ commit }) {
    try {
      const data = await api.user().getCurrentUserInfo()
      commit('setUserInfo', data)
    } catch (e) {
      console.error(e)
      //toast.error("Произошла ошибка при получении информации");
    }
  },
  async getUsers({ commit }, filters) {
    try {
      const { items, _meta } = await api.user().getUsers(filters)
      commit('setCurrentUsersPage', _meta.currentPage)
      commit('setPageUsersCount', _meta.pageCount)
      commit('setAllUsers', { items, page: filters.page })
      commit('setTotalCount', _meta.totalCount)
      commit('setFilteredUsers', items)
      commit('setUniqueUsers', items)
    } catch (e) {
      console.error(e)
      //toast.error("Произошла ошибка при получении информации");
    }
  },

  async loadPage({ dispatch }, page) {
    await dispatch('getUsers', { page })
  },

  async filterQuestionnaireUser({ commit, dispatch }, userIdVal) {
    commit('setSelectedUser', userIdVal)
    dispatch('getUsers', {
      page: 1,
      pageSize: 20,
      userId: userIdVal,
    })
  },
}

const mutations = {
  setUserInfo(state, value) {
    state.userInfo = value
  },
  setAllUsers(state, { items, page }) {
    if (page === 1) {
      state.users = items
    } else {
      state.users = [...state.users, ...items]
    }
  },
  setFilteredUsers(state, value) {
    const usersList = value?.map((el) => {
      if (!el.fio) {
        return {
          ...el,
          fio: `${el.firstname || ''} ${el.lastname || ''} ${el.middlename || ''}`,
        }
      }

      return el
    })
    state.filteredUsers = [...usersList]
  },
  setCurrentUsersPage(state, value) {
    state.currentPage = value
  },
  setPageUsersCount(state, value) {
    state.pageCount = value
  },
  setTotalCount(state, value) {
    state.totalCount = value
  },
  setUniqueUsers(state, value) {
    const userIdsSet = new Set()
    const uniqueUsersArray = []

    value.forEach((item) => {
      if (item.user !== null) {
        const userId = item.id
        if (!userIdsSet.has(userId)) {
          const userInfo = {
            id: userId,
            name: item.fio,
          }
          uniqueUsersArray.push(userInfo)
          userIdsSet.add(userId)
        }
      }
    })
    state.uniqueUsersList = uniqueUsersArray
  },
  setSelectedUser(state, value) {
    state.selectedUser = value
  },
  setSelectedGroupId(state, value) {
    state.selectedGroupId = value
  },
  updateUsersList(state, value) {
    state.filteredUsers = state.filteredUsers?.map((user) => {
      if (user.token === value.oldToken) {
        return {
          ...user,
          ...value,
        }
      }
      return user
    })
  },
}

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
